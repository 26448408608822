import React, { useState, useImperativeHandle, useRef, useCallback } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { TableCell, TableRow, IconButton, Tooltip, Button, Box } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import { Minus } from '@untitled-ui/icons-react'
import { Edit } from '@mui/icons-material'
import PopupModal from 'src/sections/components/popup'
import FieldDetailModal from 'src/sections/dashboard/field/field-detail-modal'
import usePopupModal from 'src/hooks/use-popup-modal'
import { deleteField } from 'src/api/fields'

const FieldCard = React.forwardRef(({
    cardInfo,
    setFields,
    index,
    setOriginalFields,
    onFieldDelete,
    fieldCheck,
    section,
    moveCard,
    saveFunc,
}, ref) => {
    const elementRef = useRef(null)

    // UseDrop Hook for handling drop functionality
    const [, drop] = useDrop({
		accept: 'card',
		hover(item, monitor) {
		  if (!elementRef.current) return;
		  const dragIndex = item.index;
		  const hoverIndex = index;
	  
		  // Don't replace items with themselves
		  if (dragIndex === hoverIndex) return;
	  
		  // Only proceed if they're in the same section
		  if (item.section !== section) return;
	  
		  // Determine rectangle on screen
		  const hoverBoundingRect = elementRef.current.getBoundingClientRect();
		  
		  // Get vertical middle
		  const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
		  
		  // Determine mouse position
		  const clientOffset = monitor.getClientOffset();
		  
		  // Get pixels to the top
		  const hoverClientY = clientOffset.y - hoverBoundingRect.top;
	  
		  // Only move when the mouse has crossed half of the item's height
		  // When dragging downwards, only move when the cursor is below 50%
		  if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
	  
		  // When dragging upwards, only move when the cursor is above 50%
		  if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;
	  
		  // Move the card and update the index
		  moveCard(dragIndex, hoverIndex);
		  
		  // Update the item index
		  item.index = hoverIndex;
		},
		drop() {
		  saveFunc();
		}
	  });

    // UseDrag Hook for handling drag functionality
    const [{ isDragging }, drag] = useDrag({
        type: 'card',
        item: { index, section },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    })

    drag(drop(elementRef)) // Connect drag and drop to the element

    const cardStyle = isDragging ? { backgroundColor: 'lightskyblue' } : {}
    useImperativeHandle(ref, () => ({
        getNode: () => elementRef.current,
    }))

    const [isModalOpened, setIsModalOpen] = useState(false)
    const handleOpenTheModal = () => setIsModalOpen(true)
    const handleCloseTheModal = () => setIsModalOpen(false)

    const { isModalOpen, handleDeleteClick, handleConfirmDelete, handleCloseModal } = usePopupModal(deleteField, 'Field Deleted Successfully!', onFieldDelete)

    const handleRemoveCard = useCallback((id) => {
        setFields((prev) => prev.filter((x) => x.id !== id))
        setOriginalFields((prev) => {
            const fields = prev.fields?.map((x) => {
                if (x?.id === id) {
                    return { ...x, checked: false }
                }
                return x
            })
            return { fields }
        })
    }, [setFields, setOriginalFields])

    return (
        <TableRow ref={elementRef} style={cardStyle}>
            <PopupModal isModalOpen={isModalOpen} handleConfirmDelete={handleConfirmDelete} handleCloseModal={handleCloseModal} />
            <TableCell>
                <IconButton>
                    <MenuIcon />
                </IconButton>
            </TableCell>
            <TableCell>{cardInfo?.fieldLabel}</TableCell>
            <TableCell align="right">
                {!fieldCheck ? (
                    <Tooltip title="Remove From Preset">
                        <IconButton component="a" onClick={() => handleRemoveCard(cardInfo?.id)}>
                            <Minus fontSize="small" color="#ff5555" />
                        </IconButton>
                    </Tooltip>
                ) : (
                    <>
                        {cardInfo?.isDeletable ? (
                            <Button
                                color="error"
                                variant="outlined"
                                onClick={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    handleDeleteClick(cardInfo)
                                }}
                                sx={{ marginRight: 2 }}
                            >
                                Delete
                            </Button>
                        ) : (
                            <Box sx={{ width: 120 }}></Box>
                        )}

                        <Tooltip title="Update Field">
                            <IconButton component="a" onClick={handleOpenTheModal}>
                                <Edit fontSize="small" color="#ff5555" />
                            </IconButton>
                        </Tooltip>
                        <FieldDetailModal open={isModalOpened} onClose={handleCloseTheModal} cardInfo={cardInfo} updateCards={setFields} />
                    </>
                )}
            </TableCell>
        </TableRow>
    )
})

export default FieldCard
