import { getSignedUrl } from 'src/api/forms'
import { toast } from 'react-hot-toast'
import axios from 'axios'
import { logError } from 'src/utils/logger'

export const uploadImageWithRetries = async (file) => {
  const retries = 3;
  for (let attempt = 0; attempt <= retries; attempt++) {
    try {
      const presignedPostData = await getSignedUrl(file.name)
      const imageUrl = presignedPostData.data.imageUrl
      const uploadUrl = presignedPostData.data.data.url
      const formData = new FormData()
      Object.entries(presignedPostData.data.data.fields).forEach(([key, value]) => {
        formData.append(key, value)
      })
      formData.append('file', file)

      // finally upload it to aws
      const s3UploadResult = await axios.post(uploadUrl, formData, {
        headers: {
          'Content-Type': presignedPostData.data.data.fields['Content-Type'],
          'Access-Control-Allow-Origin': '*',
        },
      })
      return { success: true, imageUrl }
    } catch (error) {
      logError(error)
      if (attempt === retries) {
        toast.error(`Failed to upload file: ${file.name}`);
        return { success: false, fileName: file.name } // Return failure result
      }
    }
  }
}

// this happens per field, since it can have multiple files, batch them
export const uploadImagesGetStringified = async (files) => {
  try {
    const batchSize = 2
    const results = []
    const failedFiles = [] // Track failed files

    for (let i = 0; i < files.length; i += batchSize) {
      const batch = files.slice(i, i + batchSize)
      const batchPromises = batch.map(async (file) => {
        const result = await uploadImageWithRetries(file)
        if (result.success) {
          return result.imageUrl
        } else {
          failedFiles.push(result.fileName) // Push failed files
          return null
        }
      })

      const batchResults = await Promise.all(batchPromises)
      results.push(...batchResults)
    }

    // Log or handle failed files
    if (failedFiles.length > 0) {
      console.error(`Failed to upload the following files: ${failedFiles.join(', ')}`)
    }

    return {
      success: failedFiles.length === 0, // Return success only if no failures
      images: JSON.stringify(results.filter(Boolean)),
      failedFiles, // Include failed files in the result
    }
  } catch (e) {
    logError(e)
    return { success: false, images: null, failedFiles: [] }
  }
}