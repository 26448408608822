import { Suspense } from 'react'
import { lazy } from 'react'

import { Layout as DashboardLayout } from 'src/layouts/dashboard'
import { AuthGuard } from 'src/guards/auth-guard'
import { Outlet } from 'react-router'
import { paths } from 'src/paths'
import FieldsList from 'src/pages/fields/fields-list'
//The following routes are not loaded lazily to enable the offline functionality:
import FormsListPage from 'src/pages/form/list'
import NewFormPage from'src/pages/form/new'
import FormDetailsPage from'src/pages/form/detail'
import SavedOrphansPage from'src/pages/orphans/saved'

const OverviewPage = lazy(() => import('src/pages/index'))
const OrphansPage = lazy(() => import('src/pages/orphans/list'))
const OrphanDetails = lazy(() => import('src/pages/orphans/detail'))
const UsersListPage = lazy(() => import('src/pages/users/list'))
const UserDetails = lazy(() => import('src/pages/users/detail'))
const NewUser = lazy(() => import('src/pages/users/new'))
const ReportsList = lazy(() => import('src/pages/reports/list'))
const ReportDetails = lazy(() => import('src/pages/reports/detail'))
const OrganisationListPage = lazy(() => import('src/pages/organisations/list'))
const NewOrganisationPage = lazy(() => import('src/pages/organisations/new'))
const OrganisationDetailPage = lazy(() => import('src/pages/organisations/detail'))
const SetPreset = lazy(() => import('src/pages/organisations/set-preset'))

export const dashboardRoutes = [
	{
		path: paths.index,
		element: (
			<DashboardLayout>
				<Suspense>
					<Outlet />
				</Suspense>
			</DashboardLayout>
		),
		children: [
			{
				index: true,
				element: (
					<AuthGuard roles={['SuperAdmin', 'Admin', 'Editor']}>
						<OverviewPage />
					</AuthGuard>
				),
			},
			{
				path: paths.users,
				element: (
					<AuthGuard roles={['SuperAdmin', 'Admin']}>
						<Outlet />
					</AuthGuard>
				),
				children: [
					{
						index: true,
						element: <UsersListPage />,
					},
					{
						path: ':userId',
						element: <UserDetails />,
					},
					{
						path: 'new',
						element: <NewUser />,
					},
				],
			},
			{
				path: paths.fields,
				element: (
					<AuthGuard roles={['SuperAdmin', 'Admin']}>
						<Outlet />
					</AuthGuard>
				),
				children: [
					{
						index: true,
						element: <FieldsList />,
					},
				],
			},
			{
				path: paths.forms,
				element: (
					<AuthGuard roles={['SuperAdmin', 'Gatherer']}>
						<Outlet />
					</AuthGuard>
				),
				children: [
					{ index: true, element: <FormsListPage /> },
					{
						path: ':formId',
						element: <FormDetailsPage />,
					},
				],
			},
			{
				path: paths.newForm,
				element: (
					<AuthGuard roles={['SuperAdmin', 'Gatherer']}>
						<NewFormPage />
					</AuthGuard>
				),
			},
			{
				path: paths.savedOrphans,
				element: (
					<AuthGuard roles={['SuperAdmin', 'Gatherer']}>
						<SavedOrphansPage />
					</AuthGuard>
				)
			},
			{
				path: paths.orphans,
				element: (
					<AuthGuard roles={['SuperAdmin', 'Admin', 'Editor']}>
						<Outlet />
					</AuthGuard>
				),
				children: [
					{
						index: true,
						element: <OrphansPage />,
					},
					{
						path: ':orphanId',
						element: <OrphanDetails />,
					},
				],
			},
			{
				path: paths.reports,
				element: (
					<AuthGuard roles={['SuperAdmin', 'Admin', 'Editor', 'Gatherer']}>
						<Outlet />
					</AuthGuard>
				),
				children: [
					{ index: true, element: <ReportsList /> },
					{
						path: ':reportId',
						element: <ReportDetails />,
					},
				],
			},
			{
				path: paths.organisations,
				element: (
					<AuthGuard roles={['SuperAdmin', 'Admin', 'Editor']}>
						<Outlet />
					</AuthGuard>
				),
				children: [
					{
						index: true,
						element: <OrganisationListPage />,
					},
					{
						path: ':organisationId',
						element: <OrganisationDetailPage />,
					},
					{
						path: ':organisationId/presets',
						element: <SetPreset />,
					},
					{
						path: 'new',
						element: <NewOrganisationPage />,
					},
				],
			},
		],
	},
]
