import React, { useState, useEffect, useCallback } from 'react'
import { wait } from 'src/utils/wait'
import { useFieldsStore } from 'src/store/fields'
import { useUserStore } from 'src/store/users'
import { useRouter } from 'src/hooks/use-router'
import { Grid, Box, Card, Chip, Container, LinearProgress, useMediaQuery, Link, Stack, SvgIcon, Typography } from '@mui/material'
import DynamicForm from 'src/sections/dashboard/forms/dynamic-form'
import { getForm, updateForm, deleteForm } from 'src/api/forms'
import { getOrphan } from 'src/api/orphans'
import { getOrganisations } from 'src/api/organisations'
import { getPublishedReportCountByOrphanId } from 'src/api/reports'
import OrganisationsAssignedTable from 'src/sections/dashboard/orphans/organisations-assigned-table'
import { toast } from 'react-hot-toast'
import { useParams } from 'react-router-dom'
import { useFormStateStore } from 'src/store/formState'
import { fileToBase64 } from 'src/utils/file-to-base64'
import { RouterLink } from 'src/components/router-link'
import { paths } from 'src/paths'
import ArrowLeftIcon from '@untitled-ui/icons-react/build/esm/ArrowLeft'
import {deleteFormResult, getFormResultById, updateFormResult} from 'src/utils/indexedDb'
import moment from 'moment/moment'
import OfflineCard from 'src/sections/dashboard/forms/offline-card'
import { PropertyList } from 'src/components/property-list'
import { PropertyListItem } from 'src/components/property-list-item'
import { logError } from 'src/utils/logger'
import { mapNonFileEntries, mapFileEntries, mapOfflineFormData } from 'src/utils/field-mapping'
import { isConnected } from 'src/api/healthCheck'

const FormDetailsPage = () => {
	const [orphanOrganizationsLoading, setOrphanOrganizationsLoading] = useState(false)
	const [formLoading, setFormLoading] = useState(false)
	const [formInfo, setFormInfo] = useState(false)
	const user = useUserStore(({ user }) => user)
	const { formId } = useParams()
	const fields = useFieldsStore(({ fields }) => fields)
	const setFields = useFieldsStore(({ setFields }) => setFields)
	const [orphan, setOrphan] = useState(null)
	const [hasPublishedReports, setHasPublishedReports] = useState(false)
	const [organisations, setOrganisations] = useState([])
	const [assignedRows, setAssignedRows] = useState([])
	const fillForm = useFormStateStore(({ fillForm }) => fillForm)
	const emptyForm = useFormStateStore(({ emptyForm }) => emptyForm)
	const [formStatus, setFormStatus] = useState('')
	const [isFormCreatedOffline, setIsFormCreatedOffline] = useState(false)
	const router = useRouter()
	const mdUp = useMediaQuery((theme) => theme.breakpoints.up('md'))
	const align = mdUp ? 'horizontal' : 'vertical'

	const getOrphansCallback = useCallback(async (orphanId) => {
		try {
			setOrphanOrganizationsLoading(true)
			const res = await getOrphan(orphanId)
			if (res?.status === 200) {
				setOrphan(res?.orphan)
				setAssignedRows(res?.orphan?.organizationsAssigned)
			}
		} catch (err) {
			logError(err)
		} finally {
			setOrphanOrganizationsLoading(false)
		}
	}, [])

	const getOrganisationsCallback = useCallback(async () => {
		try {
			setOrphanOrganizationsLoading(true)
			const res = await getOrganisations(1, 10000, '')
			setOrganisations(res?.data?.docs)
		} catch (err) {
			logError(err)
		} finally {
			setOrphanOrganizationsLoading(false)
		}
	}, [])

	const getFormDetailsCallback = useCallback(async () => {
		try {
			setFormLoading(true)
			const res = await getForm(formId)
			if (res?.status === 200) {
				setIsFormCreatedOffline(false)
				setFormStatus(res?.form?.status)
				setFormInfo(res?.form)
				
				//get the published reports count to know if we should disable one time fields
				const count_res = await getPublishedReportCountByOrphanId(res?.form?.orphanId)
				if (count_res?.status === 200)
					setHasPublishedReports(count_res?.data?.count>0)

				fillForm(res?.form.formfields)
				setFields(res?.form.formfields)
			} else {
				router.push('/form-not-found')
			}
		} catch (e) {
			const res = await getFormResultById(parseInt(formId))
			fillForm(res?.fields)
			setIsFormCreatedOffline(true)
		}
		finally {
			setFormLoading(false)
		}
	}, [user?.role, formId, setFields])

	const handleDelete = useCallback(async () => {
		setFormLoading(true)
		try {
			const result = await deleteForm(formId)
			if (result.status == 200)
				toast.success('Form Deleted Successfully!')
			else {
				logError(result, { src: 'src/pages/form/detail.jsx/FormDetailsPage/handleDelete' })
				toast.error(result.message)
			}
			await wait(500)
			router.push('/forms')
		} catch (e) {
			await deleteFormResult(parseInt(formId))
				.then(async () => {
					toast.success('Form Deleted Successfully!')
					await wait(500)
					router.push('/forms')
				})
				.catch((err) => {
					logError(err, { src: 'src/pages/form/detail.jsx/FormDetailsPage/handleDelete' })
					toast.error('Error deleting Form Result:', err)
				})
		} finally {
			setFormLoading(false)
		}
	}, [formId])

	const handleSubmit = useCallback(
		async (data, dirtyFieldsMask, status, callback) => {
			setFormLoading(true)
			try {
				if(!await isConnected())
					throw new Error('Offline')
				const imageId = import.meta.env.VITE_PROFILE_PICTURE_ID
				const entries = Object.entries(data)
				//in the case of images, value is an array of files
				//so if value[0] exists => this is a file not a string
				const fileEntries = entries.filter(([_, value]) => value?.[0]?.name)
				const nonFileEntries = entries.filter(([_, value]) => !value?.[0]?.name)

				// Map non-file entries
				const mappedNonFileEntries = mapNonFileEntries(nonFileEntries, dirtyFieldsMask)
				// Process file entries in batches
				const { fields: mappedFileEntries, imageBase64 } = await mapFileEntries(fileEntries, imageId)
				if (mappedFileEntries.error) return // Stop if there was an error in file processing

				// Combine both file and non-file fields
				const allMappedFields = [...mappedNonFileEntries, ...mappedFileEntries]

				const response = await updateForm(formId, {
					imageBase64,
					status,
					fields: allMappedFields,
				})
				if (response.status === 203) {
					toast.success('Form Updated Successfully!')
					callback()
					await wait(500)
					if (status === 'draft' || user?.role === 'SuperAdmin') {
						router.refresh()
					} else {
						router.push('/forms')
					}
				} else {
					logError(response, { src: 'src/pages/form/detail.jsx/FormDetailsPage/handleSubmit' })
					toast.error(response?.message)
				}
			} catch (e) {
				if (e.response) {
					//if axios returned an error from the backend
					if (e.response?.data) {
						logError(e, { src: 'src/pages/form/detail.jsx/FormDetailsPage/handleSubmit' })
						toast.error(e.response?.data?.message)
					}
					else {
						logError(e, { src: 'src/pages/form/detail.jsx/FormDetailsPage/handleSubmit' })
						toast.error(`There was an error creating the form: ${e.message}`)
					}
					return
				}

				//if offline & form was saved offline before editing => write the data to the indexedDB				try {
				try {
					const offlineSavedForm = await getFormResultById(parseInt(formId))
					if (!Object.keys(offlineSavedForm || {}).length) {
						return toast.error('Network connection error, please try again')
					}
				} catch (err) {
					return toast.error('Network connection error, please try again')
				}
			
				const imageId = import.meta.env.VITE_PROFILE_PICTURE_ID
				const profileImageValue = data[imageId]?.[0] ?? null
			
				let imageBase64
				if (profileImageValue) {
					try {
						imageBase64 = await fileToBase64(profileImageValue)
					} catch (err) {
						logError(err)
					}
				}
			
				const mappedFields = await mapOfflineFormData(data, dirtyFieldsMask, true)
				
				updateFormResult(parseInt(formId), {
					// for checking duplicates when syncing
					formUniqueId: new Date().getTime() + Math.floor(Math.random() * 10000000) + '',
					imageBase64,
					year: moment().year(),
					fields: mappedFields,
					status,
					token: user?.token,
				})
				.then(async () => {
					toast.success('Form Updated Successfully!')
					callback()
					await wait(500)
					router.push('/forms')
				})
				.catch((e) => {
					logError(e, { src: 'src/pages/form/detail.jsx/FormDetailsPage/handleSubmit' })
					toast.error('Error Occurred while saving the form offline', e.message)
				})
			} finally {
				setFormLoading(false)
			}
		},
		[formId, user, fields],
	)
	useEffect(() => {
		try {
			getFormDetailsCallback()
			getOrganisationsCallback()
		} catch (err) {
			logError(err)
		}
		
		return () => {
			emptyForm()
		}
	}, [])
	
	useEffect(() => {
		if(!formInfo.orphanId) return
		try {
			getOrphansCallback(formInfo.orphanId)
		} catch (err) {
			logError(err)
		}
	}, [formInfo])
	

	const [isOnline, setIsOnline] = useState(navigator.onLine)

	useEffect(() => {
		const handleOnlineEvent = async () => {
			setIsOnline(true)
			toast.success('Back Online!')
		}

		const handleOfflineEvent = () => {
			setIsOnline(false)
		}

		window.addEventListener('online', handleOnlineEvent)
		window.addEventListener('offline', handleOfflineEvent)

		return () => {
			window.removeEventListener('online', handleOnlineEvent)
			window.removeEventListener('offline', handleOfflineEvent)
		}
	}, [])
	return (
		<Box
			sx={{
				flexGrow: 1,
				py: 8,
			}}
		>
			<Container maxWidth="xl">
				<Stack spacing={4}>
					<Stack spacing={4}>
						<div>
							<Link
								color="text.primary"
								component={RouterLink}
								href={paths.forms}
								sx={{
									alignItems: 'center',
									display: 'inline-flex',
								}}
								underline="hover"
							>
								<SvgIcon sx={{ mr: 1 }}>
									<ArrowLeftIcon />
								</SvgIcon>
								<Typography variant="subtitle2">Forms</Typography>
							</Link>
						</div>
						<Stack
							alignItems="flex-start"
							direction={{
								xs: 'column',
								md: 'row',
							}}
							justifyContent="space-between"
							spacing={4}
						>
							<Stack alignItems="center" direction="row" spacing={2}>
								<Stack spacing={1}>
									<Typography variant="h4">Form Details</Typography>
									<Stack alignItems="center" direction="row" spacing={1}>
										<Typography variant="subtitle2">Form id:</Typography>
										<Chip label={formInfo?.id} size="small" />
									</Stack>
								</Stack>
							</Stack>
						</Stack>
					</Stack>
					{!isOnline && user?.role === 'Gatherer' && <OfflineCard />}
					{formLoading ? (
						<div style={{
							position: 'fixed',
							zIndex: 999,
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							width: '100vw',
							height: '100vh',
							backgroundColor: 'white',
							top: 0,
							left: 0,
						}}>
							<Card
								elevation={16}
								sx={{
									p: 4,
									mb: 2,
								}}
							>
								<Box
									sx={{
										alignItems: 'center',
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'center',
									}}
								>
									<Typography variant="h4">Loading Form...</Typography>
									<LinearProgress color="success" sx={{ width: '100%', my: 2 }} />
								</Box>
							</Card>
						</div>
					) : null}
					<Grid item xs={12}>
						<PropertyList>
							<PropertyListItem
								align={align}
								divider
								label="Status"
								value={formInfo?.status?.toUpperCase()}
								disableGutters
							/>
							<PropertyListItem
								align={align}
								divider
								label="Orphan Id"
								value={
									user?.role !== 'Gatherer' ? (
										<Link 
										href={`/orphans/${formInfo?.orphanId}`} // Set the link destination
										underline="hover"  // Optional: underline on hover
										sx={{ cursor: 'pointer' }}
										>
											{formInfo?.orphanId}
										</Link>
									):(
										`${formInfo?.orphanId}`
									)
								}
								disableGutters
							/>
							<PropertyListItem
								align={align}
								divider
								label="Year"
								value={formInfo?.year}
								disableGutters
							/>

							<PropertyListItem
								align={align}
								divider
								label="Created By"
								value={formInfo?.createdByUsername}
								disableGutters
							/>
							<PropertyListItem
								align={align}
								divider
								label="Created At"
								value={moment(formInfo?.createdAt).format('DD/MM/YYYY')}
								disableGutters
							/>
							{formInfo?.updatedByUsername && (
								<>
									<PropertyListItem
										align={align}
										divider
										label="Updated By"
										value={formInfo?.updatedByUsername}
										disableGutters
									/>
									<PropertyListItem
										align={align}
										label="Updated At"
										value={moment(formInfo?.updatedAt).format('DD/MM/YYYY')}
										disableGutters
									/>
								</>
							)}
						</PropertyList>
					</Grid>
					<OrganisationsAssignedTable
						organisations={organisations}
						loading={orphanOrganizationsLoading}
						initialRows={assignedRows || []}
						orphanId={formInfo?.orphanId}
						hideActions={true}
					/>
					<DynamicForm
						fields={fields}
						isOnline={isOnline}
						userRole={user?.role}
						handleDelete={handleDelete}
						handleSubmit={handleSubmit}
						formStatus={formStatus}
						isFormCreatedOffline={isFormCreatedOffline}
						hasPublishedReports={hasPublishedReports}
					/>
				</Stack>
			</Container>
		</Box>
	)
}

export default FormDetailsPage
