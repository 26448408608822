import { Box, Button, Modal, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'

const RejectionReasonPopupModal = ({ isModalOpen, handleSave, handleCloseModal }) => {
	const [rejectionReason, setRejectionReason] = useState('')
	return (
		<Modal
			open={isModalOpen}
			onClose={handleCloseModal}
			aria-labelledby="modal-title"
			aria-describedby="modal-description"
		>
			<Box
				sx={{
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					bgcolor: 'background.paper',
					boxShadow: 24,
					p: 4,
					width: '50%',
					textAlign: 'center',
					borderRadius: 2,
					display: 'flex',
					flexDirection: 'column',
					gap: 3, // Space between elements
				}}
			>
				<Typography id="modal-title" variant="h5" sx={{ fontWeight: 'bold', color: 'text.primary' }}>
					Rejection Reason
				</Typography>

				<TextField
					type="text"
					value={rejectionReason}
					onChange={(e) => setRejectionReason(e.target.value)}
					placeholder="Enter rejection reason"
					fullWidth
					multiline
					minRows={4} // Set minimum number of rows for multiline
					maxRows={8} // Optional, can limit the number of rows
					variant="outlined"
					sx={{
						'& .MuiOutlinedInput-root': {
							borderRadius: 2, // Rounded input field
						},
					}}
				/>

				<Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
				<Button
						variant="outlined"
						color="secondary"
						onClick={handleCloseModal}
						sx={{
							textTransform: 'none',
							px: 3,
							borderRadius: 2,
							fontWeight: 'bold',
							color: '#ff4081', // Customize cancel button color
							borderColor: '#ff4081', // Customize border color
						}}
					>
						Cancel
					</Button>
					<Button
						variant="contained"
						color="primary"
						onClick={()=>handleSave(rejectionReason)}
						sx={{
							textTransform: 'none', // Disable uppercase text
							px: 3, // Padding left and right
							borderRadius: 2,
							fontWeight: 'bold',
						}}
					>
						Save
					</Button>
				</Box>
			</Box>
		</Modal>
	)
}

export default RejectionReasonPopupModal
