import axios from 'src/api/axios'
import { logError } from 'src/utils/logger'

export const getOrganisations = async (page = 1, limit = 10, searchQuery = '') => {
	try {
		const result = await axios.get(`/organizations?page=${page}&limit=${limit}&searchQuery=${searchQuery}`)
		return {
			data: result?.data?.data,
			status: result?.data?.statusCode,
		}
	} catch (err) {
		logError(err)
	}
}
export const getOrganisation = async (id) => {
	try {
		const result = await axios.get(`/organizations/${id}`)
		return {
			organisation: result?.data?.data,
			status: result?.data?.statusCode,
		}
	} catch (err) {
		logError(err)
	}
}

export const createOrganisation = async (data) => {
	try {
		const response = await axios.post(`/organizations`, data)
		return {
			data: response.data.data,
			status: response.data.statusCode,
		}
	} catch (err) {
		return {
			message: err?.response?.data?.message,
			status: err?.response?.data?.statusCode,
		}
	}
}
export const updateOrganisation = async (id, data) => {
	try {
		const result = await axios.patch(`/organizations/${id}`, data)
		return {
			message: result?.data?.message,
			status: result?.data?.statusCode,
		}
	} catch (err) {
		return {
			message: err.response.data.message,
			status: err.response.data.statusCode,
		}
	}
}

export const deleteOrganisation = async (id) => {
	try {
		const res = await axios.delete(`/organizations/${id}`)
	} catch (err) {
		return {
			message: err.response.data.message,
			status: err.response.data.statusCode,
		}
	}
}
