import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Autocomplete, Box, Button, Chip, MenuItem, TextField, CardHeader, Grid } from '@mui/material'
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'
import CancelIcon from '@mui/icons-material/Close'
import { randomId } from '@mui/x-data-grid-generator'
import { createOrganisationOrphan, updateOrganisationOrphan } from 'src/api/organisationOrphans'
import { toast } from 'react-hot-toast'
import { logError } from 'src/utils/logger'

import './styles.css'

const OrganisationsAssignedTable = ({ organisations, loading, initialRows, orphanId, fetchOrgs, hideActions }) => {
	const [reqLoading, setReqLoading] = useState(false)
	const [rows, setRows] = useState([])
	const [rowModesModel, setRowModesModel] = useState({})
	const handleEditClick = useCallback((params) => {
		const id = params.row.id
		setRowModesModel((prev) => ({ ...prev, [id]: { mode: 'edit' } }))
	}, [])

	const filteredOrgs = useCallback(
		(params) =>
			organisations?.filter(
				(org) =>
					rows.findIndex((r) =>
						params
							? r.organizationId == org.id && r.organizationId != params.row.organizationId
							: r.organizationId == org.id,
					) == -1,
			) || [],
		[organisations, rows],
	)

	const handleAddClick = useCallback(() => {
		const id = randomId()
		// catch the organizationId if it already exists before send update request otherwise create request
		setRows((oldRows) =>
			oldRows?.length >= organisations?.length
				? oldRows
				: [
						...oldRows,
						{
							id,
							assignedOrphanId: '',
							organizationId: filteredOrgs()?.[filteredOrgs()?.length - 1]?.id,
							status: 'sponsored',
							isNew: true,
						},
				  ],
		)
		setRowModesModel((oldModel) => ({
			...oldModel,
			[id]: { mode: 'edit', fieldToFocus: 'name' },
		}))
	}, [organisations, filteredOrgs])

	const handleSaveRecord = useCallback(
		async (data, callback) => {
			try {
				setReqLoading(true)
				const res = !!data?.isNew
					? await createOrganisationOrphan(orphanId, data)
					: await updateOrganisationOrphan(data.id, {
							status: data.status,
							assignedOrphanId: data.assignedOrphanId,
							organizationId: data.organizationId,
					  })

				if (res?.status === 200) {
					toast.success(
						!!data?.isNew ? 'Orphan Assigned Successfully' : 'Assigned Orphan Updated Successfully',
					)
					!!data?.isNew && callback?.(res.data.id)
				} else if (res?.status === 403) {
					toast.error('Orphan already assigned for this organisation')
				}
			} catch (e) {
				logError(e)
			} finally {
				setReqLoading(false)
			}
		},
		[orphanId],
	)

	const handleSaveClick = useCallback(
		(params) => {
			const id = params.row.id
			setRowModesModel((prev) => ({ ...prev, [id]: { mode: 'view' } }))
			// handleSaveRecord(params.row, fetchOrgs)
			handleSaveRecord(params.row, (newId) => {
				const newRows = rows.map((x) => {
					if (x?.id === id) {
						x.id = newId
						x.isNew = false
					}
					return x
				})
				setRows(newRows)
			})
		},
		[orphanId, rows],
	)
	useEffect(() => {
		if (!loading && !!initialRows?.length) {
			setRows(initialRows)
		}
	}, [loading, initialRows])
	const handleCancelClick = useCallback(
		(params) => {
			const id = params.row?.id
			setRowModesModel((prev) => ({
				...prev,
				[id]: { mode: 'view', ignoreModifications: true },
			}))

			const editedRow = rows.find((row) => row?.id === id)
			if (editedRow.isNew) {
				setRows(rows.filter((row) => row?.id !== id))
			}
		},
		[rows],
	)
	const handleRowModesModelChange = useCallback((newRowModesModel) => {
		setRowModesModel(newRowModesModel)
	}, [])
	const organisationFieldValue = useCallback(
		(params) => organisations?.find((x) => x?.id === params.row.organizationId) || {},
		[organisations],
	)
	const isObjectNotEmpty = useCallback((obj) => {
		return Object.keys(obj).length !== Object.values(obj).filter((x) => Boolean(x?.toString())).length
	}, [])
	const handleOrganisationsChange = useCallback(
		(event, newValue, params) => {
			event.preventDefault()
			const selectedRows = rows.map((x) => {
				if (x?.id === params?.id) {
					x.organizationId = newValue?.id
				}
				return x
			})
			setRows(selectedRows)
		},
		[rows],
	)
	const handleFieldChange = useCallback(
		(e, params, field = 'assignedOrphanId') => {
			e.preventDefault()
			const selectedRows = rows.map((x) => {
				if (x?.id === params?.id) {
					x[field] = e.target.value
				}
				return x
			})
			setRows(selectedRows)
		},
		[rows],
	)

	const columns = useMemo(
		() => {
		  const baseColumns = [
			{
			  field: 'organizationId',
			  headerName: 'Organisation',
			  flex: 1.0,
			  margin: -1,
			  disableClickEventBubbling: true,
			  sortable: false,
			  disableColumnMenu: true,
			  renderCell: (params) => {
				return (
				  <Autocomplete
					variant="standard"
					fullWidth
					disableClearable
					placeholder="Select organisation"
					id="options-filled"
					disabled={rowModesModel[params?.id]?.mode !== 'edit' || !params.row.isNew}
					options={filteredOrgs(params)}
					value={organisationFieldValue(params)}
					onChange={(e, newVal) => handleOrganisationsChange(e, newVal, params)}
					renderTags={(value, getTagProps) =>
					  value?.map((option, index) => (
						<Chip variant="outlined" label={option} {...getTagProps({ index })} />
					  ))
					}
					disableUnderline
					getOptionLabel={(option) => option?.name || ''}
					renderInput={(params) => (
					  <TextField
						{...params}
						variant="standard"
						fullWidth
						placeholder="select organisation"
						InputProps={{ ...params.InputProps, disableUnderline: true }}
					  />
					)}
				  />
				)
			  },
			},
			{
			  field: 'assignedOrphanId',
			  headerName: 'Assigned Id',
			  flex: 1.0,
			  disableClickEventBubbling: true,
			  sortable: false,
			  disableColumnMenu: true,
			  renderCell: (params) => {
				return (
				  <TextField
					placeholder="assigned orphan ID"
					disabled={rowModesModel[params?.id]?.mode !== 'edit'}
					fullWidth
					onChange={(e) => handleFieldChange(e, params)}
					value={params.row.assignedOrphanId}
					variant="standard"
					InputProps={{
					  disableUnderline: true,
					}}
				  />
				)
			  },
			},
			{
			  field: 'status',
			  headerName: 'Status',
			  flex: 1.0,
			  margin: -1,
			  disableClickEventBubbling: true,
			  sortable: false,
			  disableColumnMenu: true,
			  renderCell: (params) => {
				return (
				  <TextField
					select
					fullWidth
					disabled={rowModesModel[params?.id]?.mode !== 'edit' || params.row.isNew}
					id="demo-simple-select"
					value={params.row.status || ''}
					onChange={(e) => handleFieldChange(e, params, 'status')}
					sx={{ textAlign: 'start' }}
					variant="standard"
					InputProps={{
					  disableUnderline: true,
					}}
				  >
					<MenuItem value={'sponsored'}>Sponsored</MenuItem>
					<MenuItem value={'pending'}>Pending</MenuItem>
					<MenuItem value={'canceled'}>Canceled</MenuItem>
					<MenuItem value={'terminated'}>Terminated</MenuItem>
					<MenuItem value={'duplicated'}>Duplicated</MenuItem>
				  </TextField>
				)
			  },
			}
		  ];
	  
		  // Conditionally add the "Actions" column
		  if (!hideActions) {
			baseColumns.push({
			  field: 'actions',
			  type: 'actions',
			  headerName: 'Actions',
			  flex: 0.5,
			  getActions: (params) => {
				const isInEditMode = rowModesModel[params?.id]?.mode === 'edit';
				if (isInEditMode) {
				  return [
					<GridActionsCellItem
					  disabled={isObjectNotEmpty(params.row)}
					  icon={<SaveIcon />}
					  label="Save"
					  sx={{
						color: 'primary.main',
					  }}
					  onClick={() => handleSaveClick(params)}
					/>,
					<GridActionsCellItem
					  icon={<CancelIcon />}
					  label="Cancel"
					  className="textPrimary"
					  onClick={() => handleCancelClick(params)}
					  color="inherit"
					/>
				  ];
				}
				return [
				  <GridActionsCellItem
					icon={<EditIcon />}
					label="Edit"
					className="textPrimary"
					onClick={() => handleEditClick(params)}
					color="inherit"
				  />
				];
			  }
			});
		  }
	  
		  return baseColumns;
		},
		[rowModesModel, rows, organisations, hideActions]
	  )
	  
	return (
		<>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					width: '100%',
					alignItems: 'flex-end',
				}}
			>
				<CardHeader title="Organisations Assigned" />
			{!hideActions &&
				<Grid item>
					<Button
						variant="contained"
						color="primary"
						startIcon={<AddIcon />}
						onClick={handleAddClick}
						disabled={loading || !filteredOrgs()?.length}
					>
						Assign to Organisation
					</Button>
				</Grid>
			}
			</Box>
			<Grid item xs={12}>
				<Box
					sx={{
						border: '1px solid #eee',
						borderRadius: '9px',
						height: 'auto',
						overflow: 'auto',
						width: '100%',
						'& .actions': {
							color: 'text.secondary',
						},
						'& .textPrimary': {
							color: 'text.primary',
						},
					}}
				>
					<DataGrid
						loading={loading || reqLoading}
						rows={rows}
						columns={columns}
						fullWidth
						hideFooterPagination
						hideFooter
						pageSize={100}
						pageSizeOptions={[100]}
						disableColumnMenu
						rowModes={rowModesModel}
						onRowModesChange={handleRowModesModelChange}
						componentsProps={{
							toolbar: { setRows, setRowModesModel, loading: loading || reqLoading },
						}}
						autoHeight
					/>
				</Box>
			</Grid>
		</>
	)
}

export default OrganisationsAssignedTable
