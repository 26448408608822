// Helper function to check if an object is empty
const isEmptyObject = (obj) => {
  return obj && Object.keys(obj).length === 0 && obj.constructor === Object
}

// Helper function to check if value is empty
export const isEmptyValue = (value) => {
  if (Array.isArray(value)) {
    return value.length === 0 || value.every(item => isEmptyObject(item))
  }
  return isEmptyObject(value) || value === ""
}

