export const formStatuses = [
	{
		label: 'Drafts',
		value: 'draft',
	},
	{
		label: 'Revised',
		value: 'revised',
	},
	{
		label: 'Published',
		value: 'submitted',
	},
]
