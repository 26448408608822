import axios from 'src/api/axios'

export const isConnected = async () => {
	try {
		const result = await axios.get(`/`)
		return result
	} catch (err) {
		return false
	}
}
