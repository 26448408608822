import { fileToBase64 } from 'src/utils/file-to-base64'
import { uploadImagesGetStringified } from 'src/utils/upload-images'
import { logError } from 'src/utils/logger'
import { toast } from 'react-hot-toast'
import { convertToBinary } from 'src/utils/indexedDb'
import { isEmptyValue } from 'src/utils/is-empty-value'

export const mapNonFileEntries = (entries, dirtyFieldsMask, includeAllNonNullFields = false) => {
	return entries
		.filter(([key, value]) => 
			includeAllNonNullFields
				// could be null but old existing data deleted so we need to include dirty empty fields
				? dirtyFieldsMask.hasOwnProperty(key) || (value && !isEmptyValue(value))
				: dirtyFieldsMask.hasOwnProperty(key) // Else, include only dirty fields
		)
		.map(([key, value]) => ({
			fieldId: parseInt(key),
			value: Array.isArray(value) ? JSON.stringify(value) : value || '',
		}))
}

export const mapFileEntries = async (fileEntries, imageId) => {
	let imageBase64 = null
	const batchSize = 2
	const mappedFields = []

	for (let i = 0; i < fileEntries.length; i += batchSize) {
		const batch = fileEntries.slice(i, i + batchSize)

		const batchResults = await Promise.all(
			batch.map(async ([key, value]) => {
				const result = {
					fieldId: parseInt(key),
					value: Array.isArray(value) ? JSON.stringify(value) : value || '',
				}

				// If profile image, convert to Base64
				if (imageId === key) {
					try {
						imageBase64 = await fileToBase64(value[0])
					} catch (error) {
						logError(error)
						toast.error('Failed to convert profile image to Base64')
						throw new Error('Profile image conversion to Base64 failed')
					}
				}

				// Process image files
				const imagesResult = await uploadImagesGetStringified(value)
				if (!imagesResult.success) {
					toast.error(`Failed to upload files: ${imagesResult.failedFiles.join(', ')}`)
					logError(`Failed to upload files for fieldId: ${key}`)
					throw new Error('Image upload failed')
				}

				result.value = imagesResult.images
				return result
			})
		).catch((error) => {
			logError(error)
			return {error: true, imageBase64}
		})

		if (!batchResults) {
			return { error: true, imageBase64 } // Stop processing if any batch fails
		}

		mappedFields.push(...batchResults) // Collect results from each batch
	}

	return { fields: mappedFields, imageBase64 }
}

// If `selectedOrphan.id` exists, or when we're updating an offline form, include non-null values
export async function mapOfflineFormData(data, dirtyFieldsMask, includeAllNonNullFields = false) {
	// Filter and map through form data in one step
	return await Promise.all(
		Object.entries(data)
			.filter(([key, value]) => 
				includeAllNonNullFields
					// could be null but old existing data deleted so we need to include dirty empty fields
					? dirtyFieldsMask.hasOwnProperty(key) || (value && !isEmptyValue(value)) 
					: dirtyFieldsMask.hasOwnProperty(key) // Else, include only dirty fields
			)
			.map(async ([key, value]) => {
				const isFileOrBlobArray = Array.isArray(value) && (value[0] instanceof File || value[0] instanceof Blob)
				
				// Process the value: Convert Files/Blobs, JSON stringify arrays, or leave as-is
				const processedValue = isFileOrBlobArray
					? await Promise.all(value.map(convertToBinary))
					: Array.isArray(value) ? JSON.stringify(value) : value

				// Return the structured object
				return {
					fieldId: parseInt(key),
					value: processedValue,
					fileName: isFileOrBlobArray ? value.map(x => x.name) : undefined
				}
			})
	)
}
