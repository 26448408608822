export const paths = {
	index: '/',
	pricing: '/pricing',
	login: '/login',
	orphans: '/orphans',
	organisations: '/organisations',
	newOrganisation: '/organisations/new',
	fields: '/fields',
	reports: '/reports',
	forms: '/forms',
	users: '/users',
	newUser: '/users/new',
	newForm: '/forms/new',
	savedOrphans: '/saved-orphans',
	notAuthorized: '/403',
	notFound: '/404',
	serverError: '/500',
}
