import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { deleteField } from 'src/api/fields'
import toast from 'react-hot-toast'
import { useYupValidationResolver } from 'src/hooks/useYupValidationResolver'
import { fieldValidation } from 'src/constants/fieldYupValidation'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import {
	Box,
	Button,
	Card,
	CardContent,
	Grid,
	TextField,
	Typography,
	Chip,
	Autocomplete,
	MenuItem,
	Checkbox,
	FormControlLabel 
} from '@mui/material'
import { wait } from 'src/utils/wait'
import { sections } from 'src/constants/sections'
import { logError } from 'src/utils/logger'

const FieldsForm = ({ field, loading, handleSubmit, onClose, updateCards }) => {
	const fieldPassed = useMemo(() => Boolean(field), [field])
	const [deleteLoading, setDeleteLoading] = useState(false)
	const handleDeleteField = useCallback(async () => {
		if (field?.id) {
			setDeleteLoading(true)
			try {
				const response = await deleteField(field?.id)
				if (response?.status === 200) {
					toast.success('Field Deleted Successfully!')
					await wait(750)
					updateCards((prev) => [...prev.filter((x) => x?.id !== field?.id)])
					onClose()
				} else {
					logError(response, {src:'src/pages/dashboard/field/fields-form.jsx/FieldsForm/handleDeleteField'})
					toast.error(`Can't Delete Field: ${response.message}`)
				}
			} catch (e) {
				logError(e, {src:'src/pages/dashboard/field/fields-form.jsx/FieldsForm/handleDeleteField'})
			} finally {
				setDeleteLoading(false)
			}
		}
	}, [field, updateCards, onClose])

	const getDefaultValues = useCallback(
		() => ({
			fieldLabel: field?.fieldLabel || '',
			fieldType: field?.fieldType || 'text',
			section: field?.section || sections[0],
			options: field?.options || [],
			isRequired: field?.isRequired || false,
		}),
		[field],
	)
	const resolver = useYupValidationResolver(fieldValidation)
	const formHandler = useForm({
		defaultValues: getDefaultValues(),
		resolver,
	})

	const reinitializeForm = useCallback(() => {
		if (Boolean(field?.id) && !loading) {
			formHandler.reset(getDefaultValues())
		}
	}, [field, loading])

	useEffect(() => {
		reinitializeForm()
	}, [field])

	return (
		<FormProvider {...formHandler}>
			<form>
				<Card>
					<CardContent>
						<Grid container spacing={3}>
							<Grid item md={4} xs={12}>
								<Typography variant="h6">Field details</Typography>
							</Grid>
							<Grid item md={8} xs={12}>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<Controller
											name="fieldLabel"
											control={formHandler.control}
											render={({ field: { onChange, value }, formState: { errors } }) => (
												<TextField
													fullWidth
													label="Field Label"
													name="fieldLabel"
													error={!!errors?.fieldLabel}
													helperText={errors?.fieldLabel?.message?.toString()}
													onChange={onChange}
													value={value}
												/>
											)}
										/>
									</Grid>
									<Grid item xs={12}>
										<Controller
											name="fieldType"
											control={formHandler.control}
											render={({ field: { onChange, value }, formState: { errors } }) => (
												<TextField
													select
													fullWidth
													sx={{}}
													value={value}
													onChange={onChange}
													label="Field Type"
												>
													<MenuItem value={'text'}>Text</MenuItem>
													<MenuItem value={'paragraph'}>Paragraph</MenuItem>
													<MenuItem value={'select'}>Dropdown</MenuItem>
													<MenuItem value={'multi-select'}>Multi-select</MenuItem>
													<MenuItem value={'file'}>File/Media</MenuItem>
													<MenuItem value={'table-inhouse'}>Members Inside The House</MenuItem>
													<MenuItem value={'table-outhouse'}>Siblings Outside The House</MenuItem>
												</TextField>
											)}
										/>
									</Grid>
									<Grid item xs={12}>
										<Controller
											name="section"
											control={formHandler.control}
											render={({ field: { onChange, value }, formState: { errors } }) => (
												<TextField
													select
													fullWidth
													sx={{}}
													value={value}
													onChange={onChange}
													label="section"
												>
													{sections.map((x) => (
														<MenuItem value={x} key={x}>
															{x}
														</MenuItem>
													))}
												</TextField>
											)}
										/>
									</Grid>

									{(formHandler.watch('fieldType') == 'select' ||
										formHandler.watch('fieldType') == 'multi-select') && (
											<Grid item xs={12}>
												<Controller
													name="options"
													control={formHandler.control}
													defaultValue={[]}
													render={({ field: { onChange, value }, fieldState: { error } }) => (
														<Autocomplete
															multiple
															id="options-filled"
															options={value}
															value={value}
															freeSolo
															onChange={(event, newValue) => {
																onChange(newValue)
															}}
															renderTags={(value, getTagProps) =>
																value.map((option, index) => (
																	<Chip
																		variant="outlined"
																		label={option}
																		{...getTagProps({ index })}
																	/>
																))
															}
															filterOptions={() => []}
															renderInput={(params) => (
																<TextField
																	{...params}
																	variant="filled"
																	fullWidth
																	label="Options"
																	name="options"
																	error={!!error}
																	helperText={error ? error.message : ''}
																/>
															)}
														/>
													)}
												/>
											</Grid>
									)}
									{/* Required Checkbox */}
									<Grid item xs={12}>
									<Controller
										name="isRequired"
										defaultValue={false}
										control={formHandler.control}
										render={({ field: { onChange, value } }) => (
											<FormControlLabel
											control={
												<Checkbox
												checked={value}
												onChange={onChange}
												color="primary"
												sx={{
													paddingBottom: '30px', // Remove the default padding of the checkbox
													'& .MuiSvgIcon-root': { fontSize: 24 }, // Adjust the size of the checkbox icon
												}}
												/>
											}
											label="Is this field required?"
											/>
										)}
										/>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</CardContent>
				</Card>

				<Box
					sx={{
						display: 'flex',
						flexWrap: 'wrap',
						justifyContent: 'flex-end',
						mx: -1,
						mb: -1,
						mt: 3,
					}}
				>
					{field?.isDeletable && (
						<Button
							sx={{ m: 1 }}
							variant="contained"
							color="error"
							disabled={loading || deleteLoading}
							onClick={handleDeleteField}
						>
							Delete
						</Button>
					)}
					<Button
						sx={{ m: 1 }}
						disabled={loading || !formHandler.formState.isDirty || deleteLoading}
						type="button"
						variant="contained"
						onClick={formHandler.handleSubmit((data) =>
							handleSubmit(
								data,
								formHandler.formState.dirtyFields,
								() => {
									formHandler.reset()
								},
								(message) => formHandler.setError('fieldLabel', { message }),
							),
						)}
					>
						{fieldPassed ? 'Update' : 'Create'}
					</Button>
				</Box>
			</form>
		</FormProvider>
	)
}

export default FieldsForm
