import axios from 'src/api/axios'
import { logError } from 'src/utils/logger'

export const getReports = async (page = 1, limit = 10, data, isExport) => {
	try {
		const result = await axios.post(
			`/reports?page=${page}&limit=${limit}${!!isExport ? '&isExport=true' : ''}`,
			data,
		)
		return {
			data: result?.data?.data,
			status: result?.data?.statusCode,
		}
	} catch (err) {
		logError(err)
	}
}

export const getPublishedReportCountByOrphanId = async (orphanId, organizationIds) => {
	try {
		const result = await axios.post(
			`/reports/published`,
			{orphanId, organizationIds },
		)
		return {
			data: result?.data?.data,
			status: result?.data?.statusCode,
		}
	} catch (err) {
		logError(err)
	}
}

export const getReport = async (reportId) => {
	try {
		const result = await axios.get(`/reports/${reportId}`)
		return {
			report: result?.data?.data,
			status: result?.data?.statusCode,
		}
	} catch (err) {
		logError(err)
	}
}

export const acceptReport = async (reportId) => {
	try {
		const result = await axios.patch(`/reports/accept/${reportId}`)
		return {
			message: result?.data?.message,
			status: result?.data?.statusCode,
		}
	} catch (err) {
		return {
			message: err.response.data.message,
			status: err.response.data.statusCode,
		}
	}
}

export const rejectReport = async (reportId, rejectionReason) => {
	try {
		const result = await axios.patch(`/reports/reject/${reportId}`, { rejectionReason })
		return {
			message: result?.data?.message,
			status: result?.data?.statusCode,
		}
	} catch (err) {
		return {
			message: err.response.data.message,
			status: err.response.data.statusCode,
		}
	}
}
