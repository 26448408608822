import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import {
	Box,
	Button,
	Card,
	CardContent,
	Grid,
	TextField,
	Typography,
	MenuItem,
	Autocomplete,
	IconButton,
	Chip,
	CircularProgress,
} from '@mui/material'
import HistoryIcon from '@mui/icons-material/History'
import { MuiFileInput } from 'mui-file-input'
import { sections } from 'src/constants/sections'
import { useFormStateStore } from 'src/store/formState'
import FieldHistory from './modules/field-history'
import { getOrphans } from 'src/api/orphans'
import Image from 'src/sections/components/image/image'
import { useDebounced } from 'src/hooks/use-debounced'
import styles from './styles.module.css'
import CloseIcon from '@mui/icons-material/Close'
import { Visibility } from '@mui/icons-material'
import { ChevronDown, ChevronUp } from '@untitled-ui/icons-react'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import PopupModal from 'src/sections/components/popup'
import RejectionReasonPopupModal from 'src/sections/components/rejection-reason-popop'
import usePopupModal from 'src/hooks/use-popup-modal'
import { inhouseColumns, outhouseColumns } from 'src/constants/siblingsColumns'
import SiblingsForm from 'src/components/siblingsForm'
import { useIndexedDB } from 'src/hooks/useIDB'
import { logError } from 'src/utils/logger'
import oneTimeFields from './modules/one-time-fields'

const DynamicForm = ({
	isOnline = true,
	fields,
	userRole = 'Gatherer',
	handleSubmit,
	newForm,
	selectedOrphan,
	orphan,
	handleDelete,
	setSelectedOrphan,
	report,
	updateReportStatus,
	formStatus,
	showNoOrphanHistoryMsg,
	isFormCreatedOffline,
	hasPublishedReports,
}) => {
	const [autoFocusingEnabled, setAutoFocusingEnabled] = useState(false)
	const [isOtherSelected, setIsOtherSelected] = useState({})
	const [orphansLoading, setOrphansLoading] = useState(false)
	const [inputValue, setInputValue] = useState('')
	const [focusedfieldName, setFocusedfieldName] = useState()
	const [orphanQuery, setOrphanQuery] = useState('')
	const [selectedField, setSelectedField] = useState({ id: '', name: '' })
	const [savedOrphans] = useIndexedDB('orphans', 'orphans', [])
	const form = useFormStateStore(({ form }) => form)
	const multiSelectIndices = useMemo(
		() => fields?.filter((x) => x?.fieldType === 'multi-select').map((x) => x?.id?.toString()),
		[fields],
	)
	const disableForm = useMemo(
		() => !report && !newForm && userRole === 'Gatherer' && formStatus !== 'draft' && navigator.onLine,
		[report, newForm, userRole, formStatus, navigator.onLine],
	)
	const formHandler = useForm({
		defaultValues: fields?.length
			? fields.reduce((acc, pre) => {
				acc[pre?.id?.toString()] = pre?.fieldType !== 'multi-select' ? '' : []
				return acc
			}, {})
			: {}
	})

	const formatOrphanDocForSearching = doc => (doc?.firstName || doc?.lastName || doc?.id) && `${doc?.firstName} ${doc?.lastName} - id: ${doc?.id}`.toLowerCase()
	const getOrphansCallback = useCallback(async (query, offlineSavedOrphans) => {
		if (query?.length < 1) {
			setOrphanQuery(query)
			return
		}
		setOrphansLoading(true)
		try {
			let res
			res = await getOrphans(1, 10, query)
			res = res?.data?.docs
			if (!res) {
				res = [offlineSavedOrphans.find(orp => formatOrphanDocForSearching(orp)?.includes(query.toLowerCase()))].filter(Boolean)
			}
			return res
		} catch (err) {
			logError(err)
		} finally {
			setOrphansLoading(false)
			setOrphanQuery(query)
		}
	}, [])
	const [collapsed, setCollapsed] = useState()
	const [orphans, fetchSetOrphans] = useDebounced(getOrphansCallback)
	const [isShowRejectionReasonModal, setIsShowRejectionReasonModal] = useState(false);

	useEffect(() => {
		sections.map((x) => {
			setCollapsed((prev) => ({ ...prev, [x]: false }))
		})
	}, [])
	const handleCollapse = useCallback(
		(x) => {
			setCollapsed((prev) => ({ ...prev, [x]: !Boolean(prev[x]) }))
		},
		[setCollapsed],
	)

	const handleSelectChange = (event, fieldId, selectedValue) => {
		// Update the specific field's "Other" state
		if (selectedValue.toLowerCase() === 'other') {
			setAutoFocusingEnabled(true)
			setIsOtherSelected((prev) => ({ ...prev, [fieldId]: true }))
		} else {
			setAutoFocusingEnabled(false)
			setIsOtherSelected((prev) => ({ ...prev, [fieldId]: false }))
		}
	}

	const FieldComponent = useCallback(
		(field) => {
			const isDisabledField = (!report && userRole !== 'Gatherer' && userRole !== 'SuperAdmin') 
									|| disableForm
									|| (userRole !== 'SuperAdmin' && userRole !== 'Admin'
										&& oneTimeFields.includes(field?.id)
										&& ((selectedOrphan && newForm) 
											|| (!newForm && hasPublishedReports)))
					
			// Highlight the field in red if it's required and has an error
			const fieldError = formHandler.formState.errors?.[field.id]?.message

			switch (field?.fieldType) {
				case 'text':
				case 'paragraph':
					return (
						<Box
							key={field?.id}
							sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
						>
							<Controller
								name={field?.id?.toString()}
								control={formHandler.control}
								rules={{
									required: {
									  value: !isDisabledField && field?.isRequired,
									  message: `${field?.fieldLabel} is required`,
									},
								}}
								render={({ field: { onChange, value }, fieldState: { error } }) => (
									<>
										{
											//if not location
											field?.id != import.meta.env.VITE_LOCATION_FIELD_ID ? (
												<>
													{field?.fieldLabel === "Orphan's D.O.B" ? (
														//Datepicker doesn't seem to support required field so we wrap it in a box
														<Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
														<DatePicker
														  disabled={isDisabledField}
														  label={field?.fieldLabel}
														  format="dd/MM/yyyy"
														  value={value !== '' ? new Date(value) || null : null}
														  onChange={(value) => onChange(value)}
														  renderInput={(params) => (
															<TextField
															  {...params}
															  fullWidth
															  required={!isDisabledField && field?.isRequired}
															  error={!!fieldError} // Highlight the field in red if there's an error
															/>
														  )}
														/>
														{fieldError && (
														  <Typography variant="caption" color="error">
															{fieldError}
														  </Typography>
														)}
													  </Box>
													) : (
														<TextField
															disabled={isDisabledField}
															fullWidth
															label={
																field?.fieldLabel?.toLowerCase()?.replace(/\s/g, '') ===
																	'firstname'
																	? "Orphan's First Name"
																	: field?.fieldLabel
																		?.toLowerCase()
																		?.replace(/\s/g, '') === 'lastname'
																		? "Orphan's Last Name"
																		: field?.fieldLabel
															}
															// error={Boolean(
															// 	formHandler.formState?.errors[field?.id?.toString()],
															// )}
															// helperText={
															// 	formHandler.formState?.errors[field?.id?.toString()]
															// 		?.message
															// }
															required={!isDisabledField && field?.isRequired}
															error={!!fieldError} // Mark the field with error
                    										helperText={fieldError || ''} // Show error message
															multiline={field?.fieldType === 'paragraph'}
															rows={field?.fieldType === 'paragraph' ? 4 : 1}
															onChange={onChange}
															value={value || ''}
														/>
													)}
												</>
											) : (
												<>
													{//if is location
														//has value
														value ? (
															<Grid container spacing={2}>
																<Grid item md={10} sm={8.5} xs={6}>
																	<TextField
																		disabled
																		fullWidth
																		label={field?.fieldLabel}
																		onChange={onChange}
																		value={
																			value?.split(
																				'https://www.google.com/maps?q=',
																			)[1] || ''
																		}
																	/>
																</Grid>
																<Grid item md={2} sm={3.5} xs={3}>
																	<Button
																		onClick={() => window.open(value, '_blank')}
																		variant="contained"
																		sx={{ p: 1, px: 1.5, m: 1, ml: -0.5 }}
																		startIcon={<Visibility />}
																	>
																		Map
																	</Button>
																</Grid>
															</Grid>
														) : (//is location but does not have value
															<Grid item xs={12}>
																<TextField
																	disabled
																	fullWidth
																	label={field?.fieldLabel}
																	onChange={onChange}
																	value={'N/A'}
																/>
															</Grid>
														)}
												</>
											)
										}
									</>
								)}
							/>
							{newForm && field?.id != import.meta.env.VITE_LOCATION_FIELD_ID && selectedOrphan && !isDisabledField &&  (
								<IconButton
									component="a"
									onClick={() => setSelectedField({ id: field?.id, name: field?.fieldLabel })}
								>
									<HistoryIcon variant="contained" fontSize="small" />
								</IconButton>
							)}
						</Box>
					)

				case 'select':
					return (
						<Box key={field?.id} sx={{ display: 'flex', justifyContent: 'center' }}>
							<Controller
								name={field?.id?.toString()}
								control={formHandler.control}
								rules={{
									required: {
									  value: !isDisabledField && field?.isRequired,
									  message: `${field?.fieldLabel} is required`,
									},
								}}
								render={({ field: { onChange, value }, fieldState: { errors } }) =>
									field?.options?.includes?.(value) || (!isOtherSelected[field.id] && (value === null || value ==='')) ? (
										<TextField
											select
											fullWidth
											disabled={isDisabledField}
											required={!isDisabledField && field?.isRequired}
											error={!!fieldError}
											helperText={fieldError  || ""}
											value={value || ''}
											label={field?.fieldLabel}
											onChange={(event, selectedOption) => {
												onChange(event)
												handleSelectChange(event, String(field.id), selectedOption.props.children)
											  }}
										>
											{field?.options?.sort().map((x, index) => (
												<MenuItem value={x} key={index}>
													{x}
												</MenuItem>
											))}
											<MenuItem key={field?.options?.length}>
												Other
											</MenuItem>
										</TextField>
									) : (
										<TextField
											fullWidth
											disabled={isDisabledField}
											value={value || ''}
											label={field?.fieldLabel}
											onChange={onChange}
											defaultValue=""
											required={!isDisabledField && field?.isRequired}
											error={!!fieldError}
											helperText={fieldError}
											InputProps={{
												endAdornment: !disableForm && !isDisabledField && (
													<IconButton
														aria-label="delete"
														onClick={() => {
															onChange(null)
															setAutoFocusingEnabled(false)
															setIsOtherSelected((prev) => ({ ...prev, [field.id]: false }))
														  }
														}
													>
														<CloseIcon />
													</IconButton>
												),
											}}
											autoFocus={autoFocusingEnabled}
										/>
									)
								}
							/>
							{newForm && selectedOrphan && !isDisabledField && (
								<IconButton
									component="a"
									onClick={() => setSelectedField({ id: field?.id, name: field?.fieldLabel })}
								>
									<HistoryIcon variant="contained" fontSize="small" />
								</IconButton>
							)}
						</Box>
					)
				case 'multi-select':
					return (
						<Box key={field?.id} sx={{ display: 'flex', justifyContent: 'center' }}>
							<Controller
								name={field?.id?.toString()}
								control={formHandler.control}
								rules={{
									required: {
									  value: !isDisabledField && field?.isRequired,
									  message: `${field?.fieldLabel} is required`,
									},
								}}
								render={({ field: { onChange, value }, fieldState: { error } }) => (
									<Autocomplete
										disableClearable
										fullWidth
										freeSolo
										disabled={isDisabledField}
										required={!isDisabledField && field?.isRequired}
										error={!!fieldError}
										helperText={fieldError}
										multiple
										id="options-filled"
										options={inputValue && !field?.options?.includes(inputValue) ? [...field?.options, `Add "${inputValue}"`] : field?.options}
										value={value || []}
										inputValue={focusedfieldName === field?.id ? inputValue : ''}
										onInputChange={(event, newInputValue) => {
											setInputValue(newInputValue)
										}}
										onFocus={() => setFocusedfieldName(field?.id)}
										onChange={(event, newValue) => {
											const finalValue = newValue.map(item =>
												item.startsWith('Add "') ? inputValue : item
											)
											onChange(finalValue)
											setInputValue('')
										}}
										renderTags={(value, getTagProps) =>
											Array.isArray(value) ? value.map((option, index) => (
												<Chip
													variant="outlined"
													label={option}
													{...getTagProps({ index })}
													key={option}
												/>
											)) : []
										}
										renderInput={(params) => (
											<TextField
												{...params}
												variant="filled"
												fullWidth
												label={field?.fieldLabel}
												name={field?.fieldLabel}
												disabled={isDisabledField}
												required={!isDisabledField && field?.isRequired}
												error={!!fieldError}
												helperText={fieldError}
												onBlur={(e) => {
													if (inputValue) {
														onChange([...value, inputValue])
														setInputValue('')
													}
													params.inputProps.onBlur(e)
												}}
											/>
										)}
									/>
								)}
							/>
							{newForm && selectedOrphan && !isDisabledField && (
								<IconButton
									component="a"
									onClick={() => setSelectedField({ id: field?.id, name: field?.fieldLabel })}
								>
									<HistoryIcon variant="contained" fontSize="small" />
								</IconButton>
							)}
						</Box>
					)
				case 'file':
					return (
						<Controller
							name={field?.id?.toString()}
							control={formHandler.control}
							rules={{
								required: {
								  value: !isDisabledField && field?.isRequired,
								  message: `${field?.fieldLabel} is required`,
								},
							}}
							render={
								({ field: { onChange, value }, formState: { errors } }) => (
									<Box
										sx={{
											display: 'flex',
											justifyContent: 'space-between',
											alignItems: 'center',
											flexDirection: 'row',
										}}
									>
										<MuiFileInput
											{...(value && !isDisabledField && !(value?.[0] instanceof File) && {
												InputProps: {
													endAdornment: <CloseIcon sx={{ cursor: 'pointer' }} htmlColor='#999' onClick={() => onChange('')} />
												}
											})}
											multiple
											fullWidth
											disabled={isDisabledField}
											required={!isDisabledField && field?.isRequired}
											error={!!fieldError}
											helperText={fieldError}
											label={field?.fieldLabel}
											onChange={(data) => data?.length ? onChange(data) : onChange('')}
											value={value?.[0] instanceof File ? value : undefined}
											style={{ width: '100%' }}
											{...(value?.[0] && !isOnline && {
												InputProps: {
													endAdornment: (value?.[0] && !isOnline) ? <p>awaiting internet</p> : null
												}
											})}

										/>
										{value && !(value?.[0] instanceof File) && isOnline && (
											<Image imageKey={value} className={styles.image} alt="image" />
										)}
									</Box>
								)
								// )
							}
						/>
					)
				case 'table-inhouse':
					return (
						<Controller
							name={field?.id?.toString()}
							control={formHandler.control}
							render={
								({ field: { onChange, value }, formState: { errors } }) => (
									<SiblingsForm
										title={field?.fieldLabel}
										value={value}
										onChange={onChange}
										columns={inhouseColumns}
										disabled={isDisabledField}
									/>
								)
							}
						/>
					)
				case 'table-outhouse':
					return (
						<Controller
							name={field?.id?.toString()}
							control={formHandler.control}
							render={
								({ field: { onChange, value }, formState: { errors } }) => (
									<SiblingsForm
										title={field?.fieldLabel}
										value={value}
										onChange={onChange}
										columns={outhouseColumns}
										disabled={isDisabledField}
									/>
								)
							}
						/>
					)
				default:
					return <></>
			}
		},
		[
			formHandler,
			userRole,
			selectedOrphan,
			newForm,
			isOnline,
			inputValue,
			autoFocusingEnabled,
			disableForm,
			isOtherSelected,
			report,
		],
	)
	useEffect(() => {
		if (fields?.length) {
			if (form?.length) {
				formHandler.reset(
					//convert the form fields into an object with keys of `fieldId` and values of `value`
					form?.reduce((acc, pre) => {
						if (multiSelectIndices?.includes(pre?.fieldId?.toString())) {
							try {
								acc[pre?.fieldId?.toString()] = (JSON.parse(pre?.value || '[]'))?.filter((x) => x)
							}
							catch (e) {
								acc[pre?.fieldId?.toString()] = pre?.value
							}
						} else {
							acc[pre?.fieldId?.toString()] = pre?.value
						}
						return acc
					}, {}),
				)
			} else {
				formHandler.reset(
					fields?.reduce((acc, pre) => {
						if (pre?.fieldType !== 'multi-select') {
							if (pre?.fieldType === 'select') {
								acc[pre?.id?.toString()] = null
							} else {
								acc[pre?.id?.toString()] = ''
							}
						} else {
							acc[pre?.id?.toString()] = []
						}
						return acc
					}, {}),
				)
			}
		}
	}, [form, multiSelectIndices, fields])
	const {
		isModalOpen,
		loading: deleteLoading,
		handleDeleteClick,
		handleConfirmDelete,
		handleCloseModal,
	} = usePopupModal(handleDelete, 'Deleted Successfully!', () => { }, false)
	
	const scrollToInvalidField = (error)=>{
		const firstErrorField = document.querySelector('[aria-invalid="true"]')
		if (firstErrorField) {
			firstErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' })
			return
		}

		const observer = new MutationObserver((_, obs) => {
			const firstErrorField = document.querySelector('[aria-invalid="true"]')
			if (firstErrorField) {
			  firstErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' })
			  obs.disconnect(); // Disconnect after scrolling
			}
		})
	  
		observer.observe(document.forms[0], { attributes: true, subtree: true, attributeFilter: ['aria-invalid'] })
	}

	const handleRejectionReasonSave = (rejectionReason) => {
		updateReportStatus(
			'reject',
			formHandler.formState.isDirty ? x : {},
			formHandler.formState.dirtyFields,
			rejectionReason,
			() => formHandler.reset(),
		)
		setIsShowRejectionReasonModal(false); // Close the modal after saving
	}
	  
	return (
		<>
			<RejectionReasonPopupModal
				isModalOpen={isShowRejectionReasonModal}
				handleSave={handleRejectionReasonSave}
				handleCloseModal={() => setIsShowRejectionReasonModal(false)}
			/>
			<PopupModal
				isModalOpen={isModalOpen}
				handleConfirmDelete={handleConfirmDelete}
				handleCloseModal={handleCloseModal}
			/>
			<FormProvider {...formHandler}>
				<form>
					{newForm && (
						<Grid container spacing={3}>
							<Grid item xs={4}>
								<Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
									<Autocomplete
										fullWidth
										id="options-filled"
										loading={orphansLoading}
										options={orphans || []}
										noOptionsText={orphanQuery ? 'No orphans found' : 'Search by Name/ID'}
										value={orphan}
										onInputChange={(e, value) => {
											fetchSetOrphans(value, savedOrphans)
										}}
										onChange={(e, newValue) => {
											setSelectedOrphan(newValue)
										}}
										renderTags={(value, getTagProps) =>
											value?.map((option, index) => (
												<Chip variant="outlined" label={option} {...getTagProps({ index })} />
											))
										}
										getOptionLabel={(option) =>
											`${option?.firstName} ${option?.lastName} - id: ${option?.id}` || ''
										}
										renderInput={(params) => (
											<TextField
												{...params}
												variant="filled"
												fullWidth
												label="Select Orphan"
												InputProps={{
													...params.InputProps,
													endAdornment: (
														<React.Fragment>
															{orphansLoading ? (
																<CircularProgress color="inherit" size={20} />
															) : null}
															{params.InputProps.endAdornment}
														</React.Fragment>
													),
												}}
											/>
										)}
									/>
								</Box>
								{
									showNoOrphanHistoryMsg ? (
										<p>
											No Published forms found for selected orphan
										</p>
									) : null
								}

							</Grid>
						</Grid>
					)}
					<Card sx={{ margin: '2rem 0 2rem 0' }}>
						<CardContent fullWidth>
							<Grid container spacing={3} fullWidth>
								<Grid item xs={12} marginBottom={0} fullWidth>
									<Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
										<Typography variant="h6">{sections[0]}</Typography>
										{collapsed?.[sections[0]] ? (
											<IconButton onClick={() => handleCollapse(sections[0])} sx={{ ml: 'auto' }}>
												<ChevronDown fontSize="small" />
											</IconButton>
										) : (
											<IconButton onClick={() => handleCollapse(sections[0])} sx={{ ml: 'auto' }}>
												<ChevronUp fontSize="medium" />
											</IconButton>
										)}
									</Box>
								</Grid>

								<Grid item xs={12} sx={{ display: collapsed?.[sections[0]] ? 'none' : 'block' }}>
									<Grid container spacing={1.5}>
										{fields?.map(
											(x) =>
												x.section === sections[0] &&
												!!FieldComponent(x) && (
													<Grid item key={x.id} xs={['paragraph', 'table-inhouse', 'table-outhouse'].includes(x.fieldType) ? 12 : 6}>
														{FieldComponent(x)}
													</Grid>
												),
										)}
									</Grid>
								</Grid>
							</Grid>
						</CardContent>
					</Card>

					<Grid container style={{ paddingTop: 0, marginTop: -30 }}>
						<Grid item xs={12}>
							{sections.slice(1).map((section, index) =>
								fields?.some((x) => x.section === section) ? (
									<Card sx={{ margin: '2rem 0 2rem 0' }}>
										<CardContent>
											<React.Fragment key={index}>
												{/* Add space above the section except for the first section */}
												<Grid container spacing={3} fullWidth>
													<Grid item xs={12} marginBottom={0} fullWidth>
														<Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
															<Typography variant="h6">{section}</Typography>
															{collapsed?.[section] ? (
																<IconButton
																	onClick={() => handleCollapse(section)}
																	sx={{ ml: 'auto' }}
																>
																	<ChevronDown fontSize="small" />
																</IconButton>
															) : (
																<IconButton
																	onClick={() => handleCollapse(section)}
																	sx={{ ml: 'auto' }}
																>
																	<ChevronUp fontSize="medium" />
																</IconButton>
															)}
														</Box>
													</Grid>
													<Grid
														item
														xs={12}
														sx={{ display: collapsed?.[section] ? 'none' : 'block' }}
													>
														<Grid container spacing={1.5}>
															{fields?.map(
																(x) =>
																	x.section === section &&
																	!!FieldComponent(x) && (
																		<Grid
																			item
																			key={x.id}
																			xs={x.fieldType === 'paragraph' ? 12 : 6}
																		>
																			{FieldComponent(x)}
																		</Grid>
																	),
															)}
														</Grid>
													</Grid>
												</Grid>
											</React.Fragment>
										</CardContent>
									</Card>
								) : null,
							)}
						</Grid>
					</Grid>

					<FieldHistory selectedField={selectedField} setSelectedField={setSelectedField} />
					<Box
						sx={{
							display: 'flex',
							flexWrap: 'wrap',
							justifyContent: 'space-between', // Left for Update, right for Publish/Reject
							alignItems: 'center',
							mx: -1,
							mb: -1,
							mt: 3,
						}}
						>
						{/* Left side - Update Report & Delete Form buttons */}
						{report && (
								(
									(['draft', 'reviewed_to_editor', 'revised_to_editor'].includes(report?.status) && ['Admin', 'SuperAdmin', 'Editor'].includes(userRole))
									||(['pending', 'reviewed_to_admin', 'completed'].includes(report?.status) && ['Admin', 'SuperAdmin'].includes(userRole))
								) && (
									<Button
										sx={{ m: 1 }}
										type="button"
										variant="contained"
										onClick={()=>formHandler.handleSubmit((formData) =>
											handleSubmit(formData, formHandler.formState.dirtyFields, '', () =>
												formHandler.reset(),
											), scrollToInvalidField
											)()}
									>
									Update Report
									</Button>
								)
							)
						}
						{!report && !disableForm && !newForm && (userRole === 'Gatherer' || userRole === 'SuperAdmin') && (
							<Button sx={{ m: 1 }} variant="contained" color="error" onClick={handleDeleteClick}>
								Delete
							</Button>
							)
						}

						{/* Right side - Publish and Reject buttons */}
						<Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
							{report && (
								<>
									{(
										(['draft', 'reviewed_to_editor', 'revised_to_editor'].includes(report?.status) && ['Editor', 'SuperAdmin'].includes(userRole))
										||(['pending', 'reviewed_to_admin', 'completed'].includes(report?.status) && ['Admin', 'SuperAdmin'].includes(userRole))
										) && (
										<Button
											color="error"
											variant="contained"
											onClick={() => setIsShowRejectionReasonModal(true)} // Trigger rejection modal without validation
										>
											Reject
										</Button>
										)
									}
									{
										(
											(report?.status === 'revised_to_gatherer' && ['Gatherer', 'SuperAdmin'].includes(userRole)) ||
											(report?.status === 'revised_to_editor' && ['Editor', 'SuperAdmin'].includes(userRole)) ||
											(['draft', 'reviewed_to_editor'].includes(report?.status) && ['Editor', 'SuperAdmin'].includes(userRole)) ||
											(['pending', 'reviewed_to_admin'].includes(report?.status) && ['Admin', 'SuperAdmin'].includes(userRole))
										)
										&& (
										<Button
											color="success"
											variant="contained"
											onClick={()=>formHandler.handleSubmit(
												(formData) =>{
													 updateReportStatus(
														'accept',
														formData,
														formHandler.formState.dirtyFields,
														null, // not rejected
														() => formHandler.reset(),
													)
												},
												scrollToInvalidField
											)()}
										>
										{report?.status.startsWith('revised') ? 'Resubmit' : 'Accept'}
										</Button>
										)
									}
								</>
							)}

							{(!report && (userRole === 'Gatherer' || userRole === 'SuperAdmin')) && !disableForm && (
							<>
								<Button
									sx={{ m: 1 }}
									type="button"
									variant="contained"
									onClick={() => {
										const formData = formHandler.getValues(); // Get form values without triggering validation
										handleSubmit(formData, formHandler.formState.dirtyFields, 'draft', () => {
										formHandler.reset();
										});
									}}
									disabled={(!isOnline && !newForm && !isFormCreatedOffline)}
									>
									Save as Draft
								</Button>
								<Button
								sx={{ m: 1 }}
								type="button"
								variant="contained"
								color="success"
								onClick={()=>formHandler.handleSubmit((formData) =>
									handleSubmit(formData, formHandler.formState.dirtyFields, 'submitted', () =>
									formHandler.reset()
									), scrollToInvalidField
								)()}
								disabled={!isOnline}
								>
								Publish Form
								</Button>
							</>
							)}
						</Box>
					</Box>
				</form>
			</FormProvider>
		</>
	)
}

export default DynamicForm
