import axios from 'src/api/axios'
import { logError } from 'src/utils/logger'

export const getOrganisationOrphans = async (orphanId) => {
	try {
		const result = await axios.get(`/organization-orphan/${orphanId}`)
		return {
			data: result?.data?.data,
			status: result?.data?.statusCode,
		}
	} catch (err) {
		logError(err)
	}
}

export const createOrganisationOrphan = async (orphanId, data) => {
	try {
		const response = await axios.post(`/organization-orphan/${orphanId}`, data)
		return {
			data: response.data.data,
			status: response.data.statusCode,
		}
	} catch (err) {
		return {
			message: err?.response?.data?.message,
			status: err?.response?.data?.statusCode,
		}
	}
}
export const updateOrganisationOrphan = async (id, data) => {
	try {
		const result = await axios.patch(`/organization-orphan/${id}`, data)
		return {
			message: result?.data?.message,
			status: result?.data?.statusCode,
		}
	} catch (err) {
		return {
			message: err.response.data.message,
			status: err.response.data.statusCode,
		}
	}
}
