import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { Box, Stack, Container, Typography, LinearProgress, Card } from '@mui/material'
import moment from 'moment'
import { toast } from 'react-hot-toast'

import { wait } from 'src/utils/wait'
import { useFieldsStore } from 'src/store/fields'
import { useUserStore } from 'src/store/users'
import { useRouter } from 'src/hooks/use-router'
import DynamicForm from 'src/sections/dashboard/forms/dynamic-form'
import { getFields } from 'src/api/fields'
import { createForm, getOrphanFormsHistory } from 'src/api/forms'
import { addFormResult, convertToBinary } from '../../utils/indexedDb'
import { useFormStateStore } from 'src/store/formState'
import OfflineCard from 'src/sections/dashboard/forms/offline-card'
import { fileToBase64 } from 'src/utils/file-to-base64'
import { useIndexedDB } from 'src/hooks/useIDB'
import { logError } from 'src/utils/logger'
import { mapNonFileEntries, mapFileEntries, mapOfflineFormData } from 'src/utils/field-mapping'
import { isConnected } from 'src/api/healthCheck'

const NewFormPage = () => {
	const [loading, setLoading] = useState(false)
	const [selectedOrphan, setSelectedOrphan] = useState(null)
	const [showNoOrphanHistoryMsg, setShowNoOrphanHistoryMsg] = useState(false)
	const [savedOrphanForms] = useIndexedDB('orphans', 'forms', {})
	const fields = useFieldsStore(({ fields }) => fields)
	const setFields = useFieldsStore(({ setFields }) => setFields)
	const fillForm = useFormStateStore(({ fillForm }) => fillForm)
	const fillForms = useFormStateStore(({ setOrphanForms }) => setOrphanForms)

	const getFieldsCallback = useCallback(async () => {
		setLoading(true)
		try {
			const res = await getFields()
			if (res?.status === 200) {
				setFields(res?.data?.docs)
			}
		} catch (err) {
			logError(err)
		} finally {
			setLoading(false)
		}
	}, [setFields])

	const getForms = useCallback(async (orphanId) => {
		if (!!orphanId) {
			try {
				let res
				res = await getOrphanFormsHistory(orphanId, 1, 5)
				if (!res) {
					res = savedOrphanForms[orphanId]
				}
				if (res?.forms?.docs?.length) {
					setShowNoOrphanHistoryMsg(false)
					const excludedSections = ['PHOTOS & DOCS (SUMMER)', 'PHOTOS & DOCS (WINTER)']

					fillForm(res?.forms?.docs?.[0]?.fields
						?.filter((field) => !excludedSections.includes(field.field?.section))
						?.map((x) => x?.formField)
					)
					fillForms(
						res?.forms?.docs?.map((x) => ({
							year: x?.year,
							fields: x?.fields?.map((field) => field?.formField),
						})),
					)
				} else setShowNoOrphanHistoryMsg(true)
			} catch (e) {
				logError(e)
			}
		} else {
			setShowNoOrphanHistoryMsg(false)
			fillForm([])
			fillForms([])
		}
	}, [savedOrphanForms])
	useEffect(() => {
		getForms(selectedOrphan?.id)
	}, [selectedOrphan])

	const locationId = import.meta.env.VITE_LOCATION_FIELD_ID
	const [loc, setLoc] = useState({ lat: '', lng: '' })
	const user = useUserStore(({ user }) => user)
	const router = useRouter()
	
	const getCurrentPosition = useCallback(() => {
		return new Promise((resolve, reject) => {
			navigator.geolocation.getCurrentPosition(
				(pos) => {
					const crd = pos.coords
					resolve({ lat: crd.latitude, lng: crd.longitude })
				},
				(err) => {
					reject(err)
				},
				{
					enableHighAccuracy: true,
					timeout: 30000,
					maximumAge: 0,
				},
			)
		})
	}, [])

	useEffect(() => {
		getCurrentPosition()
			.then((position) => {
				setLoc(position)
			})
			.catch((err) => {
				logError(err)
			})
	}, [])

	const handleSubmit = useCallback(
		async (data, dirtyFieldsMask, status, callback) => {
			setLoading(true)
			try {
				if(!await isConnected())
					throw new Error('Offline')
				const imageId = import.meta.env.VITE_PROFILE_PICTURE_ID
				const orphanObj = selectedOrphan?.id ? { orphanId: parseInt(selectedOrphan?.id) } : {}
				const entries = Object.entries(data)
				//in the case of images, value is an array of files
				//so if value[0] exists => this is a file not a string
				const fileEntries = entries.filter(([_, value]) => value?.[0]?.name)
				const nonFileEntries = entries.filter(([_, value]) => !value?.[0]?.name)

				// Map non-file entries
				const mappedNonFileEntries = mapNonFileEntries(nonFileEntries, dirtyFieldsMask, selectedOrphan?.id)
				// Process file entries in batches
				const { fields: mappedFileEntries, imageBase64 } = await mapFileEntries(fileEntries, imageId)
				if (mappedFileEntries.error) return // Stop if there was an error in file processing

				// Combine both file and non-file fields
				const allMappedFields = [...mappedNonFileEntries, ...mappedFileEntries]

				// Push location field if it exists
				if (loc?.lat) {
					allMappedFields.push({
						fieldId: parseInt(locationId),
						value: `https://www.google.com/maps?q=${loc.lat},${loc.lng}`
					})
				}

				const res = await createForm(
					{
						...orphanObj,
						imageBase64: imageBase64,
						year: moment().year(),
						fields: allMappedFields,
						status,
					},
					user?.token,
				)
				if (res?.status === 200) {
					toast.success('Form Created Successfully')
					callback()
					await wait(700)
					router.push('/forms')
				} else {
					logError(res, { src: 'src/pages/form/new.jsx/NewFormPage/handleSubmit' })
					toast.error(res?.message)
				}
			} catch (e) {
				//if offline => write the data to the indexedDB
				if (e.response) {
					//if error is from the server response or a generic error from the try block
					//if axios returned an error from the backend
					logError(e, { src: 'src/pages/form/new.jsx/NewFormPage/handleSubmit' })
					if (e.response?.data) {
						toast.error(e.response?.data?.message)
					}
					else {
						toast.error(`There was an error creating the form: ${e.message}`)
					}
					return
				}

				//if error is due to offline connection
				const imageId = import.meta.env.VITE_PROFILE_PICTURE_ID
				const profileImageValue = data[imageId]?.[0] ?? null
			
				let imageBase64
				if (profileImageValue) {
					try {
						imageBase64 = await fileToBase64(profileImageValue)
					} catch (err) {
						logError(err)
						toast.error(err.message)
					}
				}
				try {
					const mappedFields = await mapOfflineFormData(data, dirtyFieldsMask, selectedOrphan?.id)
					await addFormResult({
						// for checking duplicates when syncing
						formUniqueId: new Date().getTime() + Math.floor(Math.random() * 10000000) + '',
						year: moment().year(),
						imageBase64: imageBase64,
						fields: mappedFields,
						status,
						token: user?.token,
					})
				} catch (err) {
					logError(err, { src: 'src/pages/form/new.jsx/NewFormPage/handleSubmit' })
					toast.error('Error Occurred while saving the form offline', err.message)
				}

				//setLoading(true)
				toast.success('Form will be synced when back online')
				callback()
				await wait(700)
				router.push('/forms')
			} finally {
				setLoading(false)
			}
		},
		[selectedOrphan, user, loc, locationId, router, fields],
	)
	useEffect(() => {
		getFieldsCallback()
	}, [])
	const [isOnline, setIsOnline] = useState(navigator.onLine)

	useEffect(() => {
		const handleOnlineEvent = async () => {
			setIsOnline(true)
			toast.success('Back Online!')
		}

		const handleOfflineEvent = () => {
			setIsOnline(false)
		}

		window.addEventListener('online', handleOnlineEvent)
		window.addEventListener('offline', handleOfflineEvent)

		return () => {
			window.removeEventListener('online', handleOnlineEvent)
			window.removeEventListener('offline', handleOfflineEvent)
		}
	}, [])
	return (
		<Box
			sx={{
				flexGrow: 1,
				py: 8,
			}}
		>
			<Container maxWidth="xl">
				<Stack spacing={4}>
					<Stack spacing={4}>
						<Stack
							alignItems="flex-start"
							direction={{
								xs: 'column',
								md: 'row',
							}}
							justifyContent="space-between"
							spacing={4}
						>
							<Stack alignItems="center" direction="row" spacing={2}>
								<Stack spacing={1}>
									<Typography variant="h4">Create New Form</Typography>
								</Stack>
							</Stack>
						</Stack>
					</Stack>
					{!isOnline && user?.role === 'Gatherer' && <OfflineCard />}
					{loading ? (
						<Card elevation={16} sx={{ p: 4, mb: 2 }}>
							<Box
								sx={{
									alignItems: 'center',
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'center',
								}}
							>
								<Typography variant="h4">Loading Fields...</Typography>
								<LinearProgress color="success" sx={{ width: '100%', my: 2 }} />
							</Box>
						</Card>
					) : (
						<DynamicForm
							isOnline={isOnline}
							setSelectedOrphan={setSelectedOrphan}
							fields={fields}
							newForm
							orphan={selectedOrphan}
							selectedOrphan={selectedOrphan?.id}
							handleSubmit={handleSubmit}
							showNoOrphanHistoryMsg={showNoOrphanHistoryMsg}
						/>
					)}
				</Stack>
			</Container>
		</Box>
	)
}

export default NewFormPage
